export const Footer = () =>{
    return(
        <footer id="App-footer">
          <div id="Footer-cont">E-mail: ercdo@citis.ru</div>
          <div id="Footer-cont">Часы работы: 9:00 - 18:00</div>
          <div id="Footer-cont">Открытые данные</div>
          <div id="Footer-cont"><a href="https://citis.ru/" target="_blank" rel="noreferrer">ФГАНУ ЦИТиС</a></div>
          <div id="Personal-data-protect">Обработка персональных данных осуществляется в соответствии с <br /> требованиями ФЗ о защите персональных данных</div>
        </footer>
    )
}