import './AnonApp.css';
import React, {useContext} from 'react';

import {
  Routes,
  Route,
  NavLink
} from "react-router-dom";

import { defer } from 'azlib/components/helpers'
import { Async } from 'azlib/components/async'

import AnonPageMain from 'AnonAppMain';
import AnonPageOrg from 'AnonAppOrg';
import AnonPageOwner from 'AnonAppOwner';

import { Header } from 'azlib/components/header'
import { Footer } from 'azlib/components/footer'
import { GlobalPageAreas } from 'azlib/components/page-header'

import DocPage from 'docs/doc.js';

import * as gitVersion from 'git-version';

import MainApp from "MainApp";
import UserDeviceContext from 'UserDevice'

const AnonPage = () => {
  //console.log(uinfo)
  let mobile = useContext(UserDeviceContext);

  return (<>
<div id="App-anon">
<GlobalPageAreas>
<Header/>
<nav id="App-menu-anon">
  <NavLink className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "active" : ""
  }  to="/">ГЛАВНАЯ</NavLink>
  <NavLink className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "active" : ""
  } to="/org">ДЛЯ ОРГАНИЗАЦИЙ</NavLink>
  {
  <NavLink className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "active" : ""
  } to="/owner">ДЛЯ ОБЛАДАТЕЛЕЙ</NavLink>
  }
  {/*
  <NavLink className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "active" : ""
  } to="/mchd">МЧД</NavLink>
  */}
</nav>
<Async value={defer(null)}>{()=>
  <main id="App-main" className={mobile}>
    <Routes>
      <Route path="/" element={<AnonPageMain />}/>
      <Route path="/org" element={<AnonPageOrg />}/>
      <Route path="/owner" element={<AnonPageOwner />}/>
      <Route path="/mchd" element={<AnonPageMain />}/>
      <Route path="/lk/*" element={<MainApp />}/>
      <Route path="/doc">
        <Route path=":url/*" element={<DocPage/>}/>
      </Route>
      <Route path="*" element="404" />
    </Routes>
  </main>
}</Async>
<Footer/>
</GlobalPageAreas>
</div>
</>)
};

export default AnonPage;
