import React, { useContext, useState } from 'react';

import { mainDbX } from 'azlib/components/db';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { checkLoginExists, responseText, senderMail, reLogin } from 'azlib/components/access'
import { modalFormik } from 'azlib/components/formik-fields'
import { showModal } from 'azlib/components/modals';
import { MaskedInput, DateInput } from 'azlib/components/std-controls'
import { encryptData, jsonEscapeUTF, exportPbkdf2Key, encryptRSA } from 'azlib/components/crypt'
import * as yup from 'yup';

import UserInfoContext from 'UserInfo.js';

import { uinfo_changed } from 'config/common'

import css from './account.module.css'

const validationSchema = yup.object().shape({
    last_name: yup
        .string()
        .required('Введите Вашу фамилию')
    , first_name: yup
        .string()
        .required('Введите Вашe имя')
    , snils: yup
        .string()
        .trim()
        .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}$/, 'Неверный формат')
    , bdate: yup
        .date()
        .nullable()
        .typeError('Некорректная дата')
        .required("Введите дату рождения")
        .test("","Некорректная дата", (val, props) => {
            if (val instanceof Date && !isNaN(val))
            {
                if ((new Date('1920-01-01') < val)  && (new Date()) > val) return true;
            }
            return false;
        })
});

export function ChangeEmail(uinfo){
    const Content = () => {

    const [disabled, setDisabled] = useState(true)
    const [button, setButton] = useState("")

    const handleSendCode = async (uinfo, login) => {
        await senderMail('code', login, uinfo.login)
        .then((res) => {
            if(res.success){
                alert("Код подтверждения отправлен на указанную электронную почту!");
                setDisabled(false)
            }else{
                alert(res.error ?? 'Ошибка отправки кода подтверждения! Повторите попытку позднее или обратитесь в службу технической поддержки');
            }
        })

    };

    const handleReLogin = async (uinfo, new_login, code) => {
        await reLogin(uinfo, new_login, code)
    }

    return (
        <Formik initialValues={{ email: '', code: '' }}
            validationSchema={
                yup.object({
                email: yup.string()
                    .email('Неверный адрес электронной почты')
                    .required('Обязательно')
                ,
                code: button === "code" ? yup.string()
                    .matches(/^\d{4}$/, 'Код должен содержать 4 цифры')
                    .required('Обязательно') : null
            })}
            onSubmit={async (v)=>{
                let login_hash = btoa(await exportPbkdf2Key(v.email, v.email))
                let exist = await checkLoginExists(login_hash)
                if(!exist.error){
                    alert('Аккаунт с таким логином уже существует!');
                    return;
                }
                if(button === "email")
                    await handleSendCode(uinfo, v.email)
                if(button === "code")
                    await handleReLogin(uinfo, v.email, v.code)
            }}>
            <Form className="form-wrapper flexContainerColumn">
                <div className="form-title">ИЗМЕНЕНИЕ ПОЧТЫ</div>

                <p className="form-field-label">Новая почта</p>
                <div className="flexContainer">
                    <Field name="email" type="text"/>
                    <button type="submit" onClick={() => setButton("email")} >ОТПРАВИТЬ КОД</button>
                </div>
                <div className="error"><ErrorMessage name="email" /></div>

                <p className="form-field-label">Проверочный код</p>
                <div className="flexContainer">
                    <Field name="code" type="text" disabled={disabled}/>
                    <button type="submit" disabled={disabled} onClick={() => setButton("code")} >ПОДТВЕРДИТЬ</button>
                </div>
                <div className="error"><ErrorMessage name="code" /></div>
            </Form>
        </Formik>
    )
    }

    return showModal(<Content/>)
}


async function changePwd(uinfo){

    await modalFormik({
        initialValues: { pwd_old: '', pwd: '', pwd_rep: ''}
        , validationSchema:
            yup.object({
                pwd_old: yup.string()
                    .required('Обязательно')
                    .test( 'test-pwd', 'Неверный пароль',
                        async function pwdValid(value){
                            if(value && await exportPbkdf2Key(value, atob(uinfo.salt)) === uinfo.pwd)
                                return true;
                            return false;
                        }
                    )
                ,
                pwd: yup.string()
                    .required('Обязательно')
                    .min(6, 'Пароль должен быть не менее 6 символов')
                ,
                pwd_rep: yup.string()
                    .required('Обязательно')
                    .min(6, 'Пароль должен быть не менее 6 символов')
                    .oneOf([yup.ref('pwd'), null], 'Пароли должны совпадать')
            })
        , onSubmit: async (v)=>{
                await saveAccountPwd(uinfo, v)
                .then((res) => {
                    if(res.error){
                        alert(res.error);
                    }else{
                        alert("Пароль успешно изменен!")
                        window.document.location.reload()
                    }
                })
            }
        }
        ,
        formik=> <div className="form-wrapper fixed" >
            <div className="flexContainerColumn">
                <div className="form-title">ИЗМЕНЕНИЕ ПАРОЛЯ</div>

                <p className="form-field-label">Старый пароль</p>
                <Field name="pwd_old" type="password"/>
                <div className="error"><ErrorMessage name="pwd_old" /></div>

                <p className="form-field-label">Новый пароль</p>
                <Field name="pwd" type="password"/>
                <div className="error"><ErrorMessage name="pwd" /></div>

                <p className="form-field-label">Повторите пароль</p>
                <Field name="pwd_rep" type="password"/>
                <div className="error"><ErrorMessage name="pwd_rep" /></div>

                <button type="submit">ПОДТВЕРДИТЬ</button>
            </div>
        </div>
    , {closeBox:true})

}

export function AccountPage({user, ...props}){
	return(
	    <>
	        <AccountData user={user}/>
	    </>
	)
}

export function AccountData({user, ...props}) {
	let uinfo = useContext(UserInfoContext);
	if(user) uinfo = user

	return (
	    <div className={css.accountBack+" card"}>
	    <h2>Личная информация</h2>
        <Formik
            initialValues={{
                last_name: uinfo.kv.last_name
                , first_name: uinfo.kv.first_name
                , middle_name: uinfo.kv.middle_name ?? ''
                , snils: uinfo.kv.snils ?? ''
                , bdate: uinfo.kv.bdate ?? ''
                }}
            validationSchema={validationSchema}
            onSubmit={async(values, event) => {
                event.setSubmitting(false);
                await saveAccountKV(uinfo, uinfo.login, Object.assign(uinfo.kv, values))
                .then((res) => {
                    if(res.error){
                        alert(res.error);
                    }else{
                        alert("Данные сохранены!")
                        window.document.location.reload()
                    }
                })
            }}>
            {({ isSubmitting }) => (
            <Form className="inputForm">
                <div className="flexContainer">
                    <div className="flexColumn" style={{marginRight: '2em'}}>
                        <label>Фамилия</label>
                        <Field
                            name="last_name"
                            type="text"
                            placeholder="Фамилия"/>
                        <ErrorMessage name="last_name" component="div" className="error" />
                        <label>Имя</label>
                        <Field
                            name="first_name"
                            type="text"
                            placeholder="Имя"/>
                        <ErrorMessage name="first_name" component="div" className="error" />
                        <label>Отчество</label>
                        <Field
                            name="middle_name"
                            type="text"
                            placeholder="Отчество"/>
                        <ErrorMessage name="middle_name" component="div" className="error" />
                    </div>
                    <div className="flexColumn" style={{marginRight: '2em'}}>
                        <label>СНИЛС</label>
                        <Field
                            name="snils"
                            type="text"
                            placeholder="СНИЛС"
                            mask="000-000-000 00"
                            as={MaskedInput}/>
                        <ErrorMessage name="snils" component="div" className="error" />
                        <label>Дата рождения</label>
                        <Field name="bdate" as={DateInput} autoComplete="off"/>
                        <ErrorMessage name="bdate" component="div" className="error" />
                    </div>
                    <div className="flexColumn">
                        <label>Email</label>
                        <div className="flexContainer notWrap">
                            <input disabled={true} type="text" value={uinfo.kv.contact_email}/>
                            <span type="button" onClick={async()=> await ChangeEmail(uinfo)}>Изменить</span>
                        </div>
                        <label>Пароль</label>
                        <div className="flexContainer notWrap">
                            <input disabled={true} type="password" value="********"/>
                            <span type="button" onClick={async()=> await changePwd(uinfo)}>Изменить</span>
                        </div>
                    </div>
                </div>
                <br/>
                <button type="submit">Изменить</button>
            </Form>
            )}
        </Formik>
        </div>
	)
}

export async function saveAccountPwd(uinfo, values){

    let kv_new = Object.assign(uinfo.kv, values);
    let pwd = kv_new.pwd

    delete kv_new.pwd;
    delete kv_new.pwd_rep;

    let pwd_hash = await exportPbkdf2Key(pwd, atob(uinfo.salt))
    let kv = await encryptData(pwd_hash, jsonEscapeUTF(JSON.stringify(kv_new)))
    let magic_hash = btoa(await exportPbkdf2Key(await exportPbkdf2Key(atob(uinfo.magic), pwd_hash), atob(uinfo.login)))
    let rsa_pwd = await encryptRSA(pwd_hash)

    return await responseText(await mainDbX.fetch_post("/repwd/"+uinfo.login, {login: uinfo.login, pwd: rsa_pwd, kv: kv, magic_hash: magic_hash}))
    .then(async (res) => {
        console.log(res)
        if(!res.error){
            window.localStorage['UserPwd'] = pwd_hash
            await uinfo_changed()
        }
        return res
    })
}

export async function saveAccountKV(uinfo, login_hash, kv){

    let crypted_kv = await encryptData(uinfo.pwd, jsonEscapeUTF(JSON.stringify(kv)))
    let magic_hash = btoa(await exportPbkdf2Key(await exportPbkdf2Key(atob(uinfo.magic), uinfo.pwd), atob(login_hash)))

    return await mainDbX.save_direct('owners', null,{
        ownerid : uinfo.ownerid
        , login: login_hash
        , kv : crypted_kv
        , magic_hash : magic_hash
    })
    .then(async (res) => {
        if(!res.error)
            await uinfo_changed()
        return res
    })
}
