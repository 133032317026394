/**
 * log value and return it
 */
export function XLOG(v, a) {
	console.log(a??'', v)
	return v;
}

/**
 * if a value is empty ( i.e. === '' or undefined or null)
 * return placeholder value
 */
export function applyPlaceholder(v, ph) {
	return v === ''? ph : v ?? ph;
}

/**
 * check, if object is 'simple', i.e. like {}
 */
export function isPlainObject(v) {
	if(v === undefined) return false;
	if(v === null) return false;
	if(v !== Object(v)) return false;
	if(Array.isArray(v)) return false;
	if(Object.getPrototypeOf(v) === null) return true;
	return Object.getPrototypeOf(v) === Object.getPrototypeOf({});
}

/**
 * resolve promise to <value> after <delay>
 */
export const later = (delay, value) =>
    new Promise(resolve => setTimeout(resolve, delay, value));

/**
 * relolve Promose to <value> as soon, as possible
 */
export const defer = v => Promise.resolve(v)

/**
 * convert true/false to ''/null, i.e. to empty/absent attribute
 * usage: <Component attribute={htmlBool(value)} />
 */
export const htmlBool = v => v ? '' : null;

/**
 * wrap (by function call) value, if it is not empty
 * return placeholder if elmpty
 */
export function wrapNotEmpty(value, code, placeholder) {
	return value? code(value) : placeholder;
}


/**
 * if first parameter is function apply it to rest
 * if not, return it
 * 
 * so, it is can be used to mix constant or evaluated values
 * in the same property 
 * <Compoent prop="const" /> or <Component prop={(args)=>...code...} />
 */
export const func_or_const = (obj, ...args) =>
		(obj instanceof Function ? obj(...args) : obj); 


/**
 * NOTE
 * 
 * in react, this works
 * <Component {...{attribute:value}} />
 * 
 * so we can use shorthand properties almost natively
 */

/**
 * split string to fields array
 * 
 * f 
 * of pfx { f1 f2 ...}
 */
export function parse_fields(fields) {
	if(Array.isArray(fields)) return fields;

	let res = []
	const re = /([{])|([}])|([.])|([^{.}\s]+)/g
	let pfx = []
	let curr = null
	let m;
	const out = v => {
			if(curr !== null) 
				res.push([...pfx,curr].join('~')); 
			curr = v??null;
	}
	while(m=re.exec(fields)) {
		//console.log(m)
		if(m[1]) {
			//open
			pfx.push(curr); curr = null;
			continue;
		}
		if(m[2]) {
			//close
			out()
			pfx.pop()
			continue;
		}
		if(m[3]){
			out()
			res.push(pfx.join('~'))
			continue;
		}
		out(m[4])
	}
	out()
	return res
}

/**
 * call given function only once
 * and return it first value intead
 */
export function once(fn, context) { 
	let result;

	return function() { 
		if(fn) {
			result = fn.apply(context || this, arguments);
			fn = context = null;
		}

		return result;
	};
}

/**
 * restrict calls of given function
 * not frequently than specified in delay (ms)
 */
export function throttle(fn, delay, context) { 
	let result;
	let next = Date.now();

	return function() { 
		if(Date.now() >= next) {
			result = fn.apply(context || this, arguments);
			next = Date.now()+delay;
		}

		return result;
	};
}

/**
 * execute given function sequentially
 */
export function serialized(fn) {
	let executed = Promise.resolve();
	return function() {
		return executed = executed.catch(()=>null)
			.then(
				() => (fn.apply(this, arguments))
			)
	}
}


/**
 * assing value as parameter in next function
 * usage 
 * letIn( <complex-calculation> )(v=> <code> )
 * so, v is an alias for complex expression
 * somehow like
 * let x = <complex-calculation> in <code>
 */
export function letIn(value) {
	return f => f(value)
}


/**
 * convert time (in UTC) to local timezone
 */
export function toLocalTime(date) {
	//return date
	if(!date) return date;
	let d = new Date(date)
	let tzo = d.getTimezoneOffset()
	d.setUTCMinutes(d.getUTCMinutes()-tzo)
	return d.toISOString().replace('T',' ')
}


/**
 * iterate over array slice
 */
export function* sliceIterator(arr,from,to,filter) {
	to = Math.min(to??arr.length,arr.length) 
	if(filter)
		for(let i = from||0; i < to; ++i) {
			let e = arr[i]
			switch(filter(e,i,arr)) {
			case false: break;
			case true: yield e; break;
			default: return;
			}
		}
	else
		for(let i = from||0; i < to; ++i)
				yield arr[i];
}

export async function copyToBuffer(text){
    await navigator.clipboard.writeText(text).then(
        () => {
            alert('Скопировано в буфер обмена')
        },
        () => {
          alert("Не удалось скопировать автоматически!\n"+text)
        })
}