import React, {useState, useContext, useEffect, useRef} from 'react';
import { Formik, Form, Field, useField, useFormikContext
    , ErrorMessage, FormikProvider, FormikConsumer } from 'formik';

import { parse_fields, defer, applyPlaceholder, isPlainObject} from './helpers';
import { confirm, prompt, promptBig, useModalContext, showModal} from './modals';

import {findControl, findViewer} from './model.controls.js';

import {isGeneratedKey} from './local-db';
import {isEqualRowKeys} from './db'

import {localISOnow} from './calendar'
import { getKeyValues } from './local-db';


import css from './formik-fields.module.css'

export const useFormContext = useFormikContext;

export const formikExtra = Symbol('formik-extra')

export function FormValues({children}) {
  let fc = useFormikContext()
  return children(fc.values, fc)
}


function validateRequired(value) {
  if(value === undefined || value === null || value === '')
    return 'обязательное';
}
function validatePattern(pattern, value) {
  if(value &&
    !(new RegExp(`^${pattern}$`)).test(value))
    return `неверный формат`
}

export function fieldValidate(required, props, refValidate) {
    return value => 
        required && validateRequired(value)
        ||
        refValidate.current?.(value)
        || 
        props.max_length 
          && value.length > props.max_length 
          && `не более ${props.max_length} символов`
        || 
        props.pattern && validatePattern(props.pattern, value)
        || 
        Number.isNaN(value)
          && `неверный формат`
        || //last chance - custom validator if provided
        props.validate?.(value)
 }

 function labelClick(e) {
   const ne = e.target?.nextElementSibling
   if(ne?.matches?.('INPUT,TEXTAREA,SELECT'))
      ne.focus()
 }
 export const Label = (props) =>{
    return <label tabIndex={-1} onClick={labelClick} {...props} />
 }

 export function CollectFromProps(fc, props) {
    const collector = fc.status.collector;
    if(collector) {
        if(props.name) collector(props.name) 
        if(props.extra)
          for(const f of parse_fields(props.extra))
            collector(f)
      }
    return collector;
 }
 
 /**
  * readOnly
  *     true/false - as usual
  *     function - calculate
  * validation:
  *   required
  *   pattern
  *   datatype format
  *   TODO: min/max
  **/

export const LabeledFieldLabel = ({undecorated, required, readOnly, ...props}) =>
    <div className={css.labeledField} required={required}
      undecorated={undecorated===true?"":undecorated}
      >
        <Label>{props.label}</Label>
      </div>

export const LabeledFieldError = ({undecorated, required, readOnly, ...props}) =>
    <div className={css.labeledField} required={required}
      undecorated={undecorated===true?"":undecorated}
      >
          <ErrorMessage name={props.name}>{message=><div className={css.error}>{message}</div>}</ErrorMessage>
      </div>


export const LabeledField = ({tip, showError, undecorated
    , required, readOnly
    , as, ...props}) => {
    const formikProps = useFormikContext();
    const collector = CollectFromProps(formikProps, props)
    //console.log('values:', props)

    if(readOnly instanceof Function){
      readOnly = readOnly(formikProps.values, formikProps)
      //console.log('readOnly: ', readOnly)
    }
    if(required instanceof Function){
      required = required(formikProps.values, formikProps)
      //console.log('required: ', required)
    }

    const refValidate = useRef(null)
    if(as instanceof Function)
      props.refFieldValidate = refValidate 

    return <div className={css.labeledField} required={required}
      undecorated={undecorated===true?"":undecorated}
      >
      <Label>
        {props.label}
      </Label>
      { collector? null
      :
        <Field as={as}
          {...props}
          required={required} readOnly={readOnly}
          validate={fieldValidate(required,props,refValidate)}
        />
      }
      {tip? <div className={css.tip}>{tip}</div>:null}
      {showError || showError===undefined?
          <ErrorMessage name={props.name}>{message=><div className={css.error}>{message}</div>}</ErrorMessage>
          : null}
    </div>
}

/**
 * extra: optional list field name registred to fetch from db
 * children: function (take values as argument) provided calculated value
 */
export const Calculate = (props) => {
   const formikProps = useFormikContext();
   if(CollectFromProps(formikProps, props)) return

   return props.name?
            props.children?
              props.children instanceof Function?
              props.children(formikProps.values?.[props.name], formikProps.values, formikProps)
              : props.children
            :
              applyPlaceholder(formikProps.values?.[props.name], props.placeholder)
          : props.children instanceof Function?
              props.children?.(formikProps.values, formikProps)
            :
              props.children;
}

export const Conditional = (props) => {
  const formikProps = useFormikContext();
  if(CollectFromProps(formikProps, props)) 
    return props.children;

  return props.name?
            props.when(formikProps.values?.[props.name]
                , formikProps.values, formikProps.status)
                ? props.children 
                : null
          :
            props.when(formikProps.values, formikProps.status)
                ? props.children 
                : null
}

/**
 * have no name, so no validation possibe
 */
const NonameLabeledOutput = ({tip, ...props}) => {
    return <div className={css.labeledField}>
      <label>{props.label}</label>
        <output {...props}><Calculate {...props} /></output>
      {tip? <div class={css.tip}>{tip}</div>:null}
    </div>
}
/**
 * have name
 * behave like regular field
 * but validate (with custom validator) only
 */
const NamedLabeledOutput = ({tip, showError, ...props}) => {

    let [field,meta] = useField({name:props.name,validate:props.validate})

    const message = meta.error;

    return <div className={css.labeledField}>
      <label>{props.label}</label>
        <output {...props}><Calculate {...props} /></output>
      {tip? <div class={css.tip}>{tip}</div>:null}
      {(showError || showError===undefined) && message?
          <div className={css.error}>{message}</div>
          : null}
    </div>
}

export const LabeledOutput = (props) => {
  return props.name? <NamedLabeledOutput {...props} />
    : <NonameLabeledOutput {...props} />
}

export function SaveButton(props) {
  const fc = useFormikContext();
  return fc.status.modified ? 
            <button type="button" onClick={()=>fc.submitForm()} {...props} />
          : null;
}

function DummyControl(props) { return null; }

export function ModelField(props) {
  const fc = useFormikContext();
  const st = fc[formikExtra];
  let [Control, cprops] = st?
        findControl(st.storeWithFields.store, props.name, props)
        : [DummyControl, null]
  return <LabeledField {...cprops} {...props} as={Control}/>
}

export function ModelView(props) {
  const fc = useFormikContext();
  const st = fc[formikExtra];
  if(st) {
    const Viewer = findViewer(st.storeWithFields.store, props.name, props)
    return <LabeledOutput {...props}>{v => Viewer({value: v})}</LabeledOutput>
  }
  return <LabeledOutput {...props} />
}

/*
  prop formikState
  formik = current state
  if state changed, react call componentDidUpdate
  and we can push state up
  if upper state changed, we set prop 'externalState' 
  push it here and, if external state !== current state, update

  generally, we need know nothing about all state parts, exept 
  values and states
  so, if values or status changed, we push it upper
*/

class ChangeTracker extends React.Component {
  constructor(props) {
    super(props);
    this.values = null
    this.status = null
  }

  componentDidMount() {
    this.values = this.props.formik.values;
    this.props.onInitValues?.(this.props.formik.values, this.props.formik)
    this.status = this.props.formik.status;
    this.props.onInitStatus?.(this.props.formik.status, this.props.formik)
  }
  componentDidUpdate() {
    //console.log('ivc', this.props.formik.initialValues, 
    //    this.props.formik.initialValues === this.props.currentInitials,
    //    this.props.formik.values === this.props.currentInitials,
    //)
    let chValues = false
    
    if(this.values !== this.props.formik.values){
      //console.log('x-chages:', this.props.formik.values)
      this.props.onChangeValues?.(this.props.formik.values, this.values, this.props.formik)
      // and save old to new
      this.values = this.props.formik.values;
      chValues = true
    }

    if(this.status !== this.props.formik.status) {
      //console.log('x-status:')
      this.props.onChangeStatus?.(this.props.formik.status, this.status, this.props.formik)
      this.status = this.props.formik.status;
    }

    if(chValues)
      this.props.onChangeSource?.(this.values, this.status)
  }
  render() { 
    return (
      this.props.children instanceof Function 
      ? this.props.children(this.props.formik) 
      : this.props.children 
    )
  }
}

function clearRec(obj) {
  let ret = {}
  for(const i in obj) {
    if(isPlainObject(obj[i]))
      ret[i] = clearRec(obj[i])
    else
      ret[i] = ''
  }
  return ret;
}

/**
 * return true if NO deps set performed
 * (if some sets happend a new update comes later)
 **/
function handleFieldDeps(fc, dependencies, current) {
  const nset = new Set
  if(dependencies) {
    for(const fname in dependencies)
      if(fc.values[fname] !== current[fname]) {
        if(Array.isArray(dependencies[fname]))
          for(const f of dependencies[fname])
            nset.add(f) 
        else
          nset.add(dependencies[fname]) 
      }
  }
  if(nset.size) //FIXME: ensure on server too!!! with trigger
    for(const dep of nset) {
      fc.setFieldValue(dep,'')
      if(dep+'$' in fc.values) {
        fc.setFieldValue(dep+'$', clearRec(fc.values[dep+'$']))
      }
    }
  return !nset.size;
}

  //save to other storages beside state
  // it is database (TODO: trottle!)
  // or localDB -> it is pure optimisation and optional
  //FIXME: way to skip save if locally and global
export const handleSaveChangedLocal = async (values, changed, fc) => {
  //save in local DB
  //HACK: it is unclear, when no-save status should be droppes
  const {skipLocalSave, ...nstatus} = fc.status
  if(skipLocalSave) {
    // works only once! it's dirty hack!!!!
    fc.setStatus(nstatus)
    //console.log('storewithfields: ', fc[formikExtra].storeWithFields)
  } else {
    await fc[formikExtra].storeWithFields.stage(values)
    //change global status if fields changed
    fc.setStatus({...fc.status, modified: true})
  }
}

export const handleSaveChangedDirect = async (values, changed, fc) => {
  //save to database directly
  console.log('savedirect')
  if(changed) {
    let errors = await fc.validateForm()
    let keys = Object.keys(errors)
    if(!keys.length)
        await fc[formikExtra].storeWithFields.save(values) //no fake keys here
  }
}

/**
 *  field collector
 *  use dummy (empty) values with collector
 * 
*/
export function DbFieldCollector({collector, children}) {
  return <Formik 
        initialValues={{}}
        initialStatus={{collector}}
  >{formik=>
        children instanceof Function? children(formik) : children
  }</Formik>
}



/**
 * store:
 *      .store - table name
 *      .read - read form (row)
 *      .save - save form (row)
 *      .stage - autosave form (row)
 *      .fixed - fixed values
 *      .keyFields - key fields
 *      ...etc
 *  
 *  - save to server directly
 * dependencies: 
 *    map field names to list of depenedet fields
 *    if source field change, clear dependet
 * 
 * data, modifiend can comes from upper component(s)
 * so, when they changed, we shoudl sync them 
 *  
 */
export function DbFormikInt({storeWithFields
    , rowKey, data, modified, local
    , dependencies
    , onKeyChanged, onSaved, onSaving
    , onChangeData, onChangeModified
    , onDataRecieved, onSatusRecieved
    , handleSave
    , valuesRef
    , children
    }) {

  var lastDBvalues = useRef(null)

//  console.log('data', data)

  return <Formik
          initialValues={data}
          initialStatus={{modified,local}}
          onSubmit={async (values, bag) => {
              //ensure save and obtain (possible new) key
              values = (await onSaving?.(values, bag)) ?? values

              let new_key = 
                  await storeWithFields.save(values)

              bag.setFormikState(prev=>({ ...prev
                  ,values
                  ,status:{...prev.status, modified:false, local:false, skipLocalSave: true}
                }))

              if(!isEqualRowKeys(new_key, rowKey)) {
                //key is updated 
                // usually, it means that inserted values saved
                // we have to rerender whole UI from top
                onKeyChanged(new_key, values, bag)
                //reflect key change in values
                let ret = {...values}
                storeWithFields.keyFields.forEach((f,i)=>{
                  ret[f] = new_key[i]
                })
                return ret
              } else {
                onSaved?.(new_key, values, bag)
                return values;
              }
          }}
        >{formik=> {
              //extend formik context with our key 
              //console.log('F', formik.values)
              const h_formik =
              {...formik, 
                 [formikExtra]: { storeWithFields, rowKey } 
              } 
        return <FormikProvider value={h_formik}>
        <ChangeTracker formik={h_formik}
          onInitValues={(values) =>{
              onDataRecieved?.(values)
              //first init values here!
              lastDBvalues.current = values
          }}
          onChangeValues={async (values, prev, fc) =>{
            //onChangeData?.(values, prev); //notify parent
            if(handleFieldDeps(fc, dependencies, prev)) {
              //console.log('chages:', values)
              let changed = false 
              let stCnange = false
              for(const i in storeWithFields.project(lastDBvalues.current))
                if(lastDBvalues.current[i] !== values[i]){
                  changed = true; //changes found!
                }
              lastDBvalues.current = values;

              await (handleSave instanceof Function?
                handleSave // handle save
                : handleSave?
                handleSaveChangedDirect
                :
                handleSaveChangedLocal
              )(values
                  , changed
                  , fc
                )
            }
          }}
          onChangeStatus={(status,prev,fc)=>{
            if(status.modified !== prev.modified)
              onChangeModified?.(status.modified, prev.modified)
            //console.log('status: ', status)
          }}
          onChangeSource={(values, status)=>{
            onChangeData?.(values, status)
          }}
          children={children}
        />
      </FormikProvider>}
      }</Formik>
}


export class DbFormik extends React.Component {
  constructor(props) {
    super(props)

    const fields = props.store.fieldsCollector(props.extra)
    this.fields = fields;
    this.collector = (name) => fields.add(name)

    this.state = {}

    this.storeWithFields = null;
    this.rowKey = undefined;
  }

  readData() {
    const new_key = JSON.stringify(this.props.rowKey)
    if(this.rowKey === new_key) return
    if(this.rowKey === null) return
    this.rowKey = null;
    console.log('read!', new_key)
    this.storeWithFields.read(this.props.rowKey)
    .then(({data,modified,local}) => {
            //console.log('data!', data)
            this.rowKey = new_key;
            this.setState({data, modified, local});
      })
  }

  componentDidMount() {
    //console.log('MNT', this.rowKey)
    this.storeWithFields = this.props.store.withFields(
                    Array.from(this.fields)
                    )
    //console.log('FF', this.storeWithFields.fields)  
    this.readData()  //read in mount only, why?
  }
  componentDidUpdate() {
    //console.log('upd', this.rowKey)
    this.readData()      
  }

  render() {
  //two pass render
  //1. with null key --> collect
  //2. with real key --> edit data
   return !this.rowKey ? 
      <DbFieldCollector key={null}
        collector={this.collector}
        {...this.props}
      />
    : 
    this.props.rowKey && (
      this.state.modified !== null //null -> not found 
    )
    ?
    <DbFormikInt key={this.state.data?this.rowKey:null}
      {...this.props}
      storeWithFields={this.storeWithFields}
      data={this.state.data}
      modified={this.state.modified}
      local={this.state.local}
    />
    : <div>Нет такой записи</div>
  }
}

DbFormik.localSave = handleSaveChangedLocal
DbFormik.directSave = handleSaveChangedDirect



export async function statusMessage(label, placeholder, required) {
  return { status_message: (await prompt(label, placeholder, required)) || '' }
}

export async function statusMessageBig(label, placeholder, required) {
  return { status_message: (await promptBig(label, placeholder, required)) || '' }
}


async function submitWithStatus(name, fc, def, prepareSave) {
  let errors = {};
  if(def.required) {
    for(const f in def.required) {
      if(fc.values[f] === undefined ||
        fc.values[f] === null ||
        fc.values[f] === '' 
        )
        errors[f] = def.required[f] || "Обязательное";
    }
  }
  let ferrors = await fc.validateForm()
  errors = { ...ferrors, ...errors }

  if(Object.keys(errors).length > 0) {
    for(const i in errors){
      fc.setFieldError(i, errors[i])
      fc.setFieldTouched(i, true)
    }
    return;
  }
  if(def.confirm) {
    await confirm(def.confirm, true)
  }
  const prepared = await def.prepare?.(fc, def) //may throw!
  // prepared, go! 
  if(prepareSave &&  !await prepareSave(fc))
      return;
  let new_values = await fc.submitForm() //key may be changed
  console.log('saved', new_values)
  // update status if form saved (it will throw(?) else)
  let values = {
        [name]: def.to
        , status_message:''
        , ...prepared
      }
  for(const f of fc[formikExtra].storeWithFields.keyFields)
    values[f] = new_values[f]; //copy key fields
  await fc[formikExtra].storeWithFields.save(values)
  // status_stamp reflection
  values.status_stamp = localISOnow()
  if(def.reload) {
    let key = fc[formikExtra].storeWithFields.getKeyValues(values)
    let reloaded = await 
      fc[formikExtra].storeWithFields.read_direct(key, def.reload)
    values = { ...values, ...reloaded }
  }
  //status should be SAVED globally!!!! it's iter-person value
  await fc.setFormikState(prev=>
      ({...prev
      , status: {...prev.status, skipLocalSave: true}
      , values: {...prev.values, ...values}
      })
    )
}

export function StatusDriver ({map, popup, prepareSave, readOnly, ...props}) {
  const {hide} = useModalContext();
  const fc = useFormikContext();
  if(CollectFromProps(fc, props)) return
  const val = fc.values[props.name] ?? '';
  const mapper = map[val];
  if(!mapper) return;
  return <>{
    mapper
    .map(e=> <button key={e.to} type="submit" onClick={()=>{
          if(readOnly) return;
          if(popup) try{ hide?.() } catch(e) {}
          submitWithStatus(props.name, fc, e, prepareSave)
        }}
       >{e.text}</button> 
      )
  }
  </>
}

export async function cancelLocalEdit(fc, cancelEditHook){
  let store = fc[formikExtra].storeWithFields
  let key = fc[formikExtra].rowKey
  if(!cancelEditHook || await cancelEditHook?.(fc)){
    await store.reset(key)
    let vals = await store.read(key)
    await fc.setFormikState(prev=>({ ...prev
        ,status:{...prev.status, modified: vals.modified, skipLocalSave: true}
        ,values: vals.data
      }))
  }
}

export function SwitchModificationState({readOnly, canEdit, fastDoNotEdit, prepareEdit, prepareSave, cancelEdit}) {
  const fc = useFormikContext();
  return (<>
    {fc.status.modified && !readOnly && <button type="button" className="statusDriver" 
        onClick={async ()=>{
              //save form
              if(!prepareSave || await prepareSave(fc))
                fc.submitForm()
    }}>Сохранить</button>}
    
    {fc.status.modified && !readOnly && <button type="button" className="statusDriver" 
        onClick={() => cancelLocalEdit(fc, cancelEdit)}
    >Отменить редактирование</button>}
    
    {!fc.status.modified
      && !readOnly
      && !fastDoNotEdit?.(fc.values)
      && <button type="button" className="statusDriver" 
        onClick={async ()=>{
              //begin edit
              if(!canEdit || await canEdit(fc)) {
                await fc.setStatus({...fc.status, modified: true})
                await prepareEdit?.(fc)
              }
    }}>Редактировать</button>}
    </>)
}

export function IfSaved(props) {
  const fc = useFormikContext();
  if(CollectFromProps(fc, props)) 
    return props.children;
  return fc.status.modified? null: props.children;
} 
export function IfModified(props) {
  const fc = useFormikContext();
  if(CollectFromProps(fc, props)) 
    return props.children;
  return fc.status.modified? props.children : null;
} 

export function IfNewRecord(props) {
  const fc = useFormikContext();
  if(CollectFromProps(fc, props)) 
    return props.children;
  return fc.status.local ? props.children: null;
} 

export function IfExistingRecord(props) {
  const fc = useFormikContext();
  if(CollectFromProps(fc, props)) 
    return props.children;
  return fc.status.local ? null: props.children;
} 

export async function modalFormik({output, ...props}, form, modalProps){
    return await showModal(ctx=>
        <Formik
          {...props}
          onSubmit={(values, actions)=>{
            if(props.onSubmit) props.onSubmit(values, {...actions, modalContext:ctx})
            else 
              ctx.hide(output?output(values):values)
          }}
        >{formik=><Form>
        {form instanceof Function? form(formik): form}
        </Form>
        }</Formik>
      , modalProps)
}

export function ExtendFormikContext({children, value}) {
  let ctx = useFormikContext()
  return <FormikProvider value={{...ctx, ...value}}>{children}</FormikProvider>
}

export {Field, ErrorMessage} from 'formik'