import React, {useState, useEffect, useRef} from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import InputMask from 'inputmask';

import {ReactComponent as ImgCalendar} from "../img/calendar.svg";

import '../registrationForm.css';

export default function CustomDatePicker({ field, form, ...props }) {
    const { name, value } = field;
    const { setFieldValue } = form;
    const datePickerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (datePickerRef.current && datePickerRef.current.input) {
            InputMask({ mask: '99.99.9999' }).mask(datePickerRef.current.input);
        }
    }, []);

    return (
        <div className="custom-date-picker-container">
            <DatePicker
                {...props}
                className="date-picker-input"
                selected={value}
                onChange={(date) => setFieldValue(name, date)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                locale={ru}
                ref={datePickerRef}
                open={isOpen}
                onClickOutside={() => setIsOpen(false)}
                calendarClassName="custom-calendar"
                maxDate={new Date()}
            />
                <ImgCalendar className='date-picker-icon' onClick={() => setIsOpen(!isOpen)} />
        </div>
    );
}