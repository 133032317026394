import { Formik, Form, Field, ErrorMessage } from 'formik';
import React from 'react';
import NewsCont from 'NewsCont';
import * as Yup from 'yup'
import * as md5 from './md5.js';
import { promptBig, alert} from 'azlib/components/modals'
import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';
import 'indian.css';

//read news json from server
async function getNews(url)
{
    let response = await fetch();
    if (response.ok) {
    let json = await response.json();
    } else {
      alert("Ошибка HTTP: " + response.status);
    }
    return response
}


const AnonPageOwner = () => {

async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
    if (response.status == 200)
    {
     try
      {
        let ret = response.json()
        return ret
      }
      catch (err)
      {
        let rez = {
          Status: response.status
        , Message: 'Ответ сервера не разпознан'
        }
      }
    }
    //no json body
    let rez = {
      Status: response.status
    , Message: response.statusText
    }
    //let rez= await response.json();
    //console.log(response)
    return rez;

}

const handleSend =  async (values) => {
    //console.log(block_bt)

    // block_bt - block sending handle while send process, prevent multiple sends
    if (typeof values !== 'undefined' && block_bt != true)
    {
       block_bt=true;
       try {
            let str=(values.firstname + values.midname + values.lastname + values.birthdate).toString().toLowerCase();
            let dhash=md5.hex_md5(encodeURI(str))
            console.log(str);
            let send_info = {
                  email: values.email
                , ecode: values.ecode
                , dhash: dhash
            }
            //values.dhash=dhash
            //values.str=str
            let ret = await postData('/app/anonymous/validate_mail',send_info);
            if (ret.Status != 200)  alert('Ошибка! ' + ret.Message)
            else alert(ret.Message);
            block_bt=false;
        }
        catch (err)
        {
           alert('Ошибка! ')
           block_bt=false;
        }
    }
}

let block_bt=false

const [data, setData] = useState(null);
const [error, setError] = useState(null);
/*
upload news and build list. it will flow formik mail
*/
useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/public/news/news.json`
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let rez = await response.json();
        //console.log(JSON.stringify(rez));
        let fff=[];
        for (var i in rez) {

            let new_one=rez[i];
            // pic size indys style coded
            // fix it, it was writed fast and furious
            let cl_ths= 'img_wsz_'+new_one.pic_width;
            fff.push(
<div class='NewsPart'>
    <h1>{new_one.head}</h1>
    <img src={new_one.pic} alt="Image" class={cl_ths}/>
    <p>{new_one.msg}</p>
    <div class='nvpuff'> </div>
    <p class='italic_font data_setts'>Дата: {new_one.date}</p>
</div>
                );
        }
        setData(fff);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        //setLoading(false);
        //console.log('loaded');
      }
    };

    fetchData();
  }, []);

return (
<div className="App-main-anon">
<h1>ИНФОРМАЦИЯ ДЛЯ ОБЛАДАТЕЛЕЙ ЦИФРОВЫХ ДОКУМЕНТОВ</h1>
  <div id="Content-container-owner">
    <div id="Mail-content">
        <h1>ПОДТВЕРЖДЕНИЕ ПОЧТЫ</h1>
        <Formik
            initialValues={{
                email: ''
                , midname: ''
                , firstname: ''
                , lastname: ''
                , birthdate: ''
                , ecode: ''
            }}
            validationSchema={Yup.object().shape({
                 email: Yup.string().email('Ошибка в адресе почты').required('Обязательное поле')
               , ecode: Yup.string().required('Обязательное поле')
               , firstname: Yup.string().required('Обязательное поле')
               , lastname: Yup.string().required('Обязательное поле')
               , birthdate: Yup.date().nullable()
                    .typeError('Некорректная дата')
                    .required("Введите дату рождения")
                    .test("","Некорректная дата",
                    (val, props) => {
                        if (val instanceof Date && !isNaN(val))
                        {
                            if ((new Date('1920-01-01') < val)  && (new Date()) > val) return true;
                        }
                        return false;
                    })
            })}
            onSubmit={async (values, actions) => {
                //await new Promise((r) => setTimeout(r, 500));
                actions.setSubmitting(false);
                handleSend(values,this);
                block_bt=false
                //alert(JSON.stringify(values, null, 2));
            }}
        >
        {({ isSubmitting, values, actions }) => (
            <Form id="Mail-form">
                <label>E-mail</label>
                <Field id="Text-field" name="email" type="email" />
                <ErrorMessage name="email" className="error" component="div"/>
                <button disabled={block_bt} onClick={(e: any) => {
                    e.preventDefault();
                    handleSend({email: values.email});
                }}>Получить проверочный код</button>
                <label>Проверочный код</label>
                <Field id="Text-field" name="ecode" />
                <ErrorMessage name="ecode" className="error" component="div"/>
                <label>Фамилия</label>
                <Field id="Text-field" name="lastname" />
                <ErrorMessage name="lastname" className="error" component="div"/>
                <label>Имя</label>
                <Field id="Text-field" name="firstname" />
                <ErrorMessage name="firstname" className="error" component="div"/>
                <label>Отчество</label>
                <Field id="Text-field" name="midname" />
                <ErrorMessage name="midname" className="error" component="div"/>
                <label>Дата рождения</label>
                <Field id="Date-field" name="birthdate" type="date" value={values.birthdate}/>
                <ErrorMessage name="birthdate" className="error" component="div"/>
                <button type="submit" disabled={isSubmitting} >Подтвердить почту</button>
            </Form>
            )}
        </Formik>
    </div>
    {data}
  </div>
</div>)
};

export default AnonPageOwner;