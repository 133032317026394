export const titledName = (pp, login) =>{
    let p = pp?.kv ?? pp

	return p?.last_name ?
	[ p?.last_name
		,
		[p?.first_name?.substr(0,1)
		, p?.middle_name?.substr(0,1)
		].filter(Boolean).map(e=>e+'.').join('')
	].join(' ')
	+ ( login? `(${p?.login})`: '')
	: p?.login
}

export const fullPersonName = (pp, login) =>{
    let p = pp?.kv ?? pp

	return p?.last_name ?
	[ p?.last_name
		,
		[p?.first_name
		, p?.middle_name
		].filter(Boolean).join(' ')
	].join(' ')
	+ " " +( login? `(${p?.login})`: '')
	: p?.login
}
