
export function getUinfoCookie() {
    const matches = document.cookie.match(/(?:^|; )uinfo_lk=([^;]*)/);
    return matches?.[1];
}

export function getUinfoTmpCookie() {
    const matches = document.cookie.match(/(?:^|; )uinfo_tmp=([^;]*)/);
    return matches?.[1] ? decodeURIComponent(matches?.[1]) : '';
}

export function getPeerCookie() {
    const matches = document.cookie.match(/(?:^|; )peer_code=([^;]*)/);
    return matches?.[1];
}

export function hasAZstate() {
    const matches = document.cookie.match(/(?:^|; )AZstate_lk=([^;]*)/);
    return !!matches?.[1];
}
