import React from 'react';

import {ReactComponent as TutorialLinux} from "./app-img/Tutorial_linux.svg"
import {ReactComponent as TutorialWindows} from "./app-img/Tutorial_windows.svg"
import {ReactComponent as TutorialSchool} from "./app-img/Tutorial_school.svg"
import {ReactComponent as TutorialSpo} from "./app-img/Tutorial_spo.svg"
import {ReactComponent as TutorialDpo} from "./app-img/Tutorial_dpo.svg"
import {ReactComponent as TutorialVo} from "./app-img/Tutorial_vo.svg"
import {ReactComponent as Divider} from "./app-img/Divider.svg"
import { Link } from 'react-router-dom';
import NewsCont from 'NewsCont';

const AnonPageOrg = () => {
    return (
<div className="App-main-anon">
  <div id="Content-container-org">
    <h1>Важная информация</h1>
    <div id="Important-org">
      <Link id="Org-link" to="/public/orgs/1_Инструкция_регистрация_+_доступ_Школы.docx" target="_blank" rel="noreferrer"><TutorialSchool></TutorialSchool></Link>
      <Link id="Org-link" to="/public/orgs/1_Инструкция_регистрация_+_доступ_СПО.docx" target="_blank" rel="noreferrer"><TutorialSpo></TutorialSpo></Link>
      <Link id="Org-link" to="/public/orgs/1_Инструкция_регистрация_ДПО_и_ПО.docx" target="_blank" rel="noreferrer"><TutorialDpo></TutorialDpo></Link>
      <Link id="Org-link" to="/public/orgs/1_Инструкция_регистрация_+_доступ_ВО.docx" target="_blank" rel="noreferrer"><TutorialVo></TutorialVo></Link>
      <Divider id="Org-divider"></Divider>
      <Link id="Org-link" to="/public/Tutorial_Linux.docx" download="Tutorial_Linux" target="_blank" rel="noreferrer"><TutorialLinux></TutorialLinux></Link>
      <Link id="Org-link" to="/public/Tutorial_Windows.docx" download="Tutorial_Windows" target="_blank" rel="noreferrer"><TutorialWindows></TutorialWindows></Link>
    </div>
    <h1>Новости ЦДО</h1>
    <NewsCont />
  </div>
</div>)
};

export default AnonPageOrg;