import { PUBLIC_KEY } from 'azlib/components/publickey';

const AES = 'AES-CBC'

const HMAC_ALGO = {
    name: 'HMAC',
    hash: 'SHA-256'
};

const RSA = {
    name: 'RSA-OAEP',
    hash: 'SHA-256'
}

export function jsonEscapeUTF(s) {return s.replace(/[^\x20-\x7F]/g, x => "\\u" + ("000"+x.codePointAt(0).toString(16)).slice(-4))}

function buf2hex(buffer) { // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)]
      .map(x => x.toString(16).padStart(2, '0'))
      .join('');
}

function hex2buf(hexString) {
    if (hexString.length % 2 != 0) {
        console.log('WARNING: expecting an even number of characters in the hexString');
    }

    var bad = hexString.match(/[G-Z\s]/i);
    if (bad) {
        console.log('WARNING: found non-hex characters', bad);
    }

    var pairs = hexString.match(/[\dA-F]{2}/gi);
    var integers = pairs.map(function(s) {
        return parseInt(s, 16);
    });

    var array = new Uint8Array(integers);
    return array.buffer;
}

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function ab2str(buf) {
  return String.fromCharCode(...new Uint8Array(buf));
}

export function generateRandStr(len){
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return ab2str(arr)
}

export function generateRandText(len){
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, (dec) => dec.toString(16).padStart(2, "0")).join('')
}

const generateAesKey = async () =>
    window.crypto.subtle.generateKey(
        {
            name: AES,
            length: 128,
        }
        , true
        , ['encrypt', 'decrypt']
    )

const importAesKey = async (key) =>
    window.crypto.subtle.importKey(
        "raw",
        str2ab(key),
        AES,
        true,
        ['encrypt', 'decrypt']
    )

const importHmacKey = async (key) =>
    window.crypto.subtle.importKey(
        "raw",
        str2ab(key),
        HMAC_ALGO,
        true,
        ["sign", "verify"]
    )

const generateIv = () =>
    window.crypto.getRandomValues(new Uint8Array(16))

const importPbkdf2Key = async (key) =>
    window.crypto.subtle.importKey(
        "raw",
        str2ab(key),
        {
            name: "PBKDF2",
        },
        false,
        ["deriveKey", "deriveBits"]
    )

const importRSAPubkey = async (key) =>
    window.crypto.subtle.importKey(
        "jwk",
        key,
        RSA,
        false,
        ["encrypt"]
    )

export const derivePbkdf2Key = async(key, salt = null) =>
    window.crypto.subtle.deriveKey(
        {
            "name": "PBKDF2",
            salt: salt ? str2ab(salt) : '',
            iterations: 1000,
            hash: {name: "SHA-256"},
        },
        await importPbkdf2Key(key),
        {
            name: AES,
            length: 128,
        },
        true,
        ["encrypt", "decrypt"]
    )

export async function exportPbkdf2Key(key, salt) {
    let exported = await derivePbkdf2Key(key, salt);
    exported = await window.crypto.subtle.exportKey("raw", exported);
    return ab2str(exported);
}

const hashHmac = async (key, data) => {
    let hmackey = await window.crypto.subtle.sign(
            "HMAC",
            await importHmacKey(key),
            str2ab(data)
        )

    return new Uint8Array(hmackey)
}

export const encryptData = async (key, data) => {
    const aeskey = await importAesKey(key)
    const iv = generateIv()
    const cipher = new Uint8Array(await window.crypto.subtle.encrypt({
        name: AES,
        iv: iv
    }, aeskey, str2ab(data)))

    const hmac = await hashHmac(key, ab2str(cipher))

    let mergedArray = new Uint8Array(iv.length + hmac.length + cipher.length);
    mergedArray.set(iv)
    mergedArray.set(hmac, iv.length)
    mergedArray.set(cipher, iv.length + hmac.length)

    return btoa(ab2str(mergedArray))
}

export const decryptData = async (key, packed) => {
    try{
        const string = window.atob(packed)

        let iv = str2ab(string.substring(0, 16))
        let hmac = str2ab(string.substring(16, 48))
        let cipher = str2ab(string.substring(48))

        let aeskey = await importAesKey(key)
        let hmackey = await importHmacKey(key)

        let result = await window.crypto.subtle.verify(
            "HMAC",
            hmackey,
            hmac,
            cipher,
        );

        if(result){
            const encoded = await window.crypto.subtle.decrypt(
                {
                    name : AES,
                    iv: iv
                },
                aeskey,
                cipher
            )
            var ans = ab2str(encoded)
            if(!ans)
                throw "incorrect"
            return ans
        }
    //FIXME: drop crypto-js after 07.10, this is a temporary code!!!
    }catch(e){
        const CryptoJS = require("crypto-js");

        var base64data = CryptoJS.enc.Base64.parse(packed);
        var encrypted = new CryptoJS.lib.WordArray.init(base64data.words.slice(12), base64data.sigBytes - 48); // consider significant bytes
        var iv = new CryptoJS.lib.WordArray.init(base64data.words.slice(0, 4));
        var hmac = new CryptoJS.lib.WordArray.init(base64data.words.slice(4, 12));
        var calchmac = CryptoJS.HmacSHA256(encrypted, CryptoJS.enc.Utf8.parse(key));

        if(calchmac.toString() == hmac.toString()){
            var cipher = CryptoJS.lib.CipherParams.create({ ciphertext: encrypted });
            var decrypted = CryptoJS.AES.decrypt(cipher, CryptoJS.enc.Utf8.parse(key), {iv: iv, mode: CryptoJS.mode.CFB, padding: CryptoJS.pad.NoPadding}); // disable padding
            return decrypted.toString(CryptoJS.enc.Utf8);
        }
        return null;
    }
    return null
}

function appendBuffer(buffer1, buffer2) {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
};

const EVP_BytesToKey = async (salt, pass) => {
    let bytes = new Uint8Array([])
    let last = new Uint8Array([])
    let saltbuf = str2ab(salt)
    let passbuf = str2ab(pass)

    while(bytes.byteLength < 16){
        let tmp = appendBuffer(last, passbuf)
        last = await crypto.subtle.digest("SHA-256", appendBuffer(tmp, saltbuf))
        bytes = appendBuffer(bytes, last)
    }
    return ab2str(bytes)
}

export const decryptDataFromOpenSSL = async (key, packed) => {
    const string = ab2str(hex2buf(packed))

    let iv = str2ab(string.substring(0, 16))
    let hmac = str2ab(string.substring(16, 48))
    let cipher = str2ab(string.substring(48))
    let ciphertext = str2ab(string.substring(64))

    let salt = string.substring(56, 64)
    let key_openssl = await EVP_BytesToKey(salt, key)
    let aeskey = await importAesKey(key_openssl.substring(0, key_openssl.length/2))
    let hmackey = await importHmacKey(key)

    let result = await window.crypto.subtle.verify(
        "HMAC",
        hmackey,
        hmac,
        cipher,
    );

    if(result){
        const encoded = await window.crypto.subtle.decrypt(
            {
                name : AES,
                iv: iv
            },
            aeskey,
            ciphertext
        )
        return ab2str(encoded)
    }
    return null
}

export async function digestSHA256(message) {
    return ab2str(await window.crypto.subtle.digest("SHA-256", str2ab(message)));
}

export const encryptRSA = async (data) => {
    let pubkey = await importRSAPubkey(PUBLIC_KEY)
    let encoded = await window.crypto.subtle.encrypt(
        RSA
        , pubkey
        , str2ab(data)
    )
    return btoa(ab2str(encoded))
}