import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { exportPbkdf2Key } from 'azlib/components/crypt'
import { alert, showModal } from "azlib/components/modals";
import CustomDatePicker  from "./components/customDatePicker";
import { validationSchemas } from "./components/regValidations";
import { checkLoginExists
    , loginFOne
    , loginFTwo
    , registerFOne
    , registerFTwo
    , senderMail
} from "azlib/components/access";

import { ReactComponent as ImgLogo } from "app-img/logo1.svg";
import { ReactComponent as ImgRectangle } from "app-img/rectangle.svg";
import { ReactComponent as ImgStepBack } from "app-img/stepBack.svg";
import { ReactComponent as ImgLine } from "./img/line.svg";

import './registrationForm.css';

const STATES = {
    GET_EMAIL: 1,
    GET_PASSWORD: 2,
    VERIFY_CODE: 3,
    REGISTER_DETAILS: 4,
    VERIFY_REGISTER_CODE: 5
};

const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    code: '',
    lastName: '',
    firstName: '',
    middleName: '',
    birthDate: null
};

export function OwnerLoginButton({...props}){
    return(
    <button className="Button-header" onClick={async (e)=>{
                        await RegistrationForm();
                }}
        >{props.children}</button>
    )
}

export function RegistrationForm() {
    const Content = () => {
        const [step, setStep] = useState(STATES.GET_EMAIL);
        const [formData, setFormData] = useState(initialValues);
        const [userData, setUserData] = useState({});
        const [submitAction, setSubmitAction] = useState(null);
        const [errors, setErrors] = useState([])

        const handleNext = (values, custom_step = null) => {
            setFormData({ ...formData, ...values });
            if(custom_step)
                setStep(custom_step);
            else
                setStep(step + 1);
        };

        const handleResetPassword = () => {
            alert('Для восстановления доступа к учетной записи обратитесь в службу технической поддержки!');
        };

        const handleBack = () => {
            setErrors([]);
            if (step === STATES.REGISTER_DETAILS) return setStep(STATES.GET_EMAIL);
            setStep(step - 1);
        };

        const handleResendCode = async (login, mode) => {
            let res = await senderMail('code', login) || {}
            if(res.error){
                alert(res.error);
            }else{
                alert('Код подтверждения отправлен на указанную электронную почту!');
            }
        };

        const handleSubmitForm = async (values, event) => {
            let err_arr = []
            if(submitAction === 'login'){
                switch(step) {
                    case STATES.GET_EMAIL:
                    {
                        let login_hash = btoa(await exportPbkdf2Key(values.email, values.email))
                        let exist = await checkLoginExists(login_hash)
                        if(exist.error){
                            err_arr.push(exist.error)
                        }
                        else{
                            setUserData({ ...userData, ...{email: values.email, login : login_hash, magic : exist.magic, salt : exist.salt}})
                            handleNext(values);
                        }
                        break;
                    }
                    case STATES.GET_PASSWORD:
                    {
                        let pwd_hash = await exportPbkdf2Key(values.password, atob(userData.salt))
                        let res = await loginFOne(values.email
                            , userData.login
                            , pwd_hash
                            , userData.magic
                            , userData.salt) || {}

                        if(res.error){
                            err_arr.push(res.error);
                        }else{
                            setUserData({ ...userData, ...{pwd_hash: pwd_hash}})
                            handleNext(values);
                        }
                        break;
                    }
                    case STATES.VERIFY_CODE:
                    {
                        let res = await loginFTwo(userData, values.code)
                        if(res.error){
                            err_arr.push(res.error);
                            break;
                        }
                        if(window.document.location.pathname.startsWith("/doc"))
                            window.document.location.reload()
                        else
                            window.open("/lk","_self")
                        break;
                    }
                    default:
                        handleNext(values);
                }
            }
            else if(submitAction === 'register'){
                switch(step) {
                    case STATES.GET_EMAIL:
                    {
                        let login_hash = btoa(await exportPbkdf2Key(values.email, values.email))
                        let exist = await checkLoginExists(login_hash)
                        if(!exist.error){
                            err_arr.push('Аккаунт с таким логином уже существует!');
                        }else{
                            setStep(STATES.REGISTER_DETAILS)
                        }
                        break;
                    }
                    case STATES.REGISTER_DETAILS:
                    {
                        let res = await registerFOne(values) || {}
                        if(res.error){
                            err_arr.push(res.error);
                        }else{
                            handleNext(values);
                        }
                        break;
                    }
                    case STATES.VERIFY_REGISTER_CODE:
                    {
                        let res = await registerFTwo(values) || {}
                        if(res.error){
                            err_arr.push(res.error);
                        }
                        break;
                    }
                    default:
                        handleNext(values);
                }
            }
            setErrors(err_arr);
            event.setSubmitting(false);
        }

        return (
            <div className="registration-container" >
                <div className="form-wrapper fixed" >
                    <ImgStepBack style={{marginLeft:"-45px"}} className={`back-button ${step === 1 ? 'hidden' : ''}`} onClick={handleBack}/>
                    <div className="form-title">Личный кабинет</div>
                    <div className="logo-container">
                        <ImgRectangle className="registration-background" />
                        <ImgLogo className="registration-logo" />
                    </div>
                    <Formik
                        initialValues={formData}
                        validationSchema={validationSchemas[step]}
                        onSubmit={ async (values, event) => await handleSubmitForm(values, event) }
                    >
                        {({ isSubmitting }) => (
                            <Form className="flexContainerColumn">
                                {step === STATES.GET_EMAIL && (
                                    <>
                                        <p className="form-field-label">E-mail</p>
                                        <Field type="email" name="email" />
                                        <ErrorMessage name="email" component="div" className="error" />
                                        <button type="submit" onClick={() => {
                                            setSubmitAction("login");
                                            }}>Вход</button>
                                        <button type="submit" onClick={() => {
                                            setSubmitAction("register");
                                            }}>
                                            Регистрация
                                        </button>
                                    </>
                                )}
                                {step === STATES.GET_PASSWORD && (
                                    <>
                                        <p className='form-section-title'>Вход</p>
                                        <p className="form-email">{formData.email}</p>
                                        <p className="form-field-label">Пароль</p>
                                        <Field type="password" name="password" />
                                        <ErrorMessage name="password" component="div" className="error" />
                                        <button type="submit">Получить код</button>
                                        <ImgLine className='separator-line'/>
                                        <p className='reset-password' onClick={handleResetPassword}>Забыли пароль?</p>
                                    </>
                                )}
                                {step === STATES.VERIFY_CODE && (
                                    <>
                                        <p className='form-section-title'>Подтверждение входа</p>
                                        <p className="form-email">{formData.email}</p>
                                        <p className="form-field-label">Код</p>
                                        <Field type="text" name="code" />
                                        <ErrorMessage name="code" component="div" className="error" />
                                        <div className="resend-code-container">
                                            <p className='resend-info'>Код подтверждения отправлен на почту {formData.email}</p>
                                            <button className="resend-code-button" onClick={()=>{
                                                handleResendCode(formData.email, 'login');
                                            }}>Отправить код повторно
                                            </button>
                                        </div>
                                        <button type="submit" disabled={isSubmitting}>
                                            Вход
                                        </button>
                                    </>
                                )}
                                {step === STATES.REGISTER_DETAILS && (
                                    <>
                                        <p className='form-section-title'>Регистрация</p>
                                        <p className="form-field-label">E-mail</p>
                                        <Field type="email" name="email"/>
                                        <ErrorMessage name="email" component="div" className='error'/>
                                        <p className="form-field-label">Фамилия</p>
                                        <Field type="text" name="lastName" />
                                        <ErrorMessage name="lastName" component="div" className='error'/>
                                        <p className="form-field-label">Имя</p>
                                        <Field type="text" name="firstName" />
                                        <ErrorMessage name="firstName" component="div" className='error'/>
                                        <p className="form-field-label">Отчество</p>
                                        <Field type="text" name="middleName" />
                                        <ErrorMessage name="middleName" component="div" className='error'/>
                                        <p className="form-field-label">Дата рождения</p>
                                        <Field component={CustomDatePicker} name="birthDate"/>
                                        <ErrorMessage name="birthDate" component="div" className='error'/>
                                        <p className="form-field-label">Пароль</p>
                                        <Field type="password" name="password" />
                                        <ErrorMessage name="password" component="div" className='error'/>
                                        <p className="form-field-label">Подтвердите пароль</p>
                                        <Field type="password" name="confirmPassword" />
                                        <ErrorMessage name="confirmPassword" component="div" className='error'/>
                                        <button type="submit">Получить код</button>
                                    </>
                                )}
                                {step === STATES.VERIFY_REGISTER_CODE && (
                                    <>
                                        <p className='form-section-title'>Регистрация</p>
                                        <p className="form-email">{formData.email}</p>
                                        <p className="form-field-label">Код</p>
                                        <Field type="text" name="code"/>
                                        <ErrorMessage name="code" component="div" className='error'/>
                                        <div className="resend-code-container">
                                            <p className='resend-info'>Код подтверждения отправлен на почту {formData.email}</p>
                                            <button className="resend-code-button" onClick={()=>{
                                                handleResendCode(formData.email, 'register');
                                            }}>Отправить код повторно
                                            </button>
                                        </div>
                                        <button type="submit" disabled={isSubmitting}>Зарегистрироваться</button>
                                    </>
                                )}

                                {errors.length > 0 ? errors.map(error => <><br/><div className='form-title error'>{error}</div></>) : null}
                            </Form>
                        )}
                    </Formik>
                    </div>
            </div>
        )
    }

    return showModal(<Content/>)
}
