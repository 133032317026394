
const timerInterval = 2000

let sse = null

let last_ping = 0

let connected = null;

export function SSEConnected() {
	return connected;
}

let listeners1 = {

}

let listeners = {

}

export function registerSSEListener(event, f) {
	if(!listeners[event]) listeners[event] = []
	listeners[event].push(f)
}

export function unregisterSSEListener(event, f) {
	if(!listeners[event]) listeners[event] = []
	listeners[event]
		= listeners[event].filter(e=>e!==f)
	//TODO: check
}

export function registerSSEListenerFocused(event, f) {
	if(!listeners1[event]) listeners1[event] = []
	listeners1[event].push(f)
}
export function unregisterSSEListenerFocused(event, f) {
	if(!listeners1[event]) listeners1[event] = []
	listeners1[event]
		= listeners1[event].filter(e=>e!==f)
}

const bc = new BroadcastChannel("icc-manager");

function notifyEvent(event, data, secondary) {
	if(!secondary) {
		bc.postMessage({event, data})
	}
	let a = listeners1[event]
	if(a)
		for(const f of a){
			f(data,event)
		}
	a = listeners[event]
	if(a)
		for(const f of a){
			f(data,event,secondary)
		}
}

function channelLocals(url) {
	let s = window.localStorage['ICC:'+url] ?? '{}'
	return JSON.parse(s)
}

function channelLocalsPush(url, event) {
	let s = channelLocals(sse.url_src)
	s.lastEventId = event.lastEventId
	let ret = false;
	if(!s.seen?.includes(s.lastEventId)) {
		ret = true
		s.seen = (s.seen||[])
		s.seen.push(s.lastEventId)
		if(s.seen.length > 100)
			s.seen = s.seen.slice(s.seen.length-100)
	}
	window.localStorage['ICC:'+url] = JSON.stringify(s)
	return ret
}

export function createChannel(url) {
	const first_call = !sse
	sse = new EventSource(url
		+
		'?last_event_id='+ encodeURIComponent(channelLocals(url).lastEventId||'')
		,{withCredentials:true})
	sse.url_src = url
	sse.onmessage = (event) => {
	}
	sse.onopen = (event) => {
		//console.log('sse open', sse.url)
	}
	sse.addEventListener("ping", (event) =>{
		//every second if connected
		notifyEvent('ping', last_ping, true)
	})
	sse.addEventListener("message", (event) =>{
		// focused window only
		// get message type and message text
		//console.log("SSE message", event)
		if(channelLocalsPush(sse.url_src, event)) {
			let data = event.data ?? '{}'
			data = JSON.parse(data)

			if(data.event)
				notifyEvent(data.event, data)
		}
	})
	if(first_call) {
		registerSSEListener('ping', () => {last_ping = Date.now()})

		bc.onmessage = (event)=>{
			notifyEvent(event.data.event, event.data.data, true)
		}
		window.addEventListener('focus', ()=>{
			//console.log('focus sse')
			if(sse?.readyState === 2) {
				//sse recreate if was closed
				createChannel(sse.url_src)
				if(connected)
					last_ping = Date.now()
			}
		})
		window.addEventListener('blur', ()=>{
			//console.log('blur sse')
			if(sse && sse.readyState !== 2) {
				//sse.close() if open
				//sse recreate if was closed
				sse.close()
			}
		})
		setInterval(()=>{
			//every two seconds
			//console.log('t', connected, Date.now()-last_ping)
			if(sse.readyState === 2) return
			if(!connected && last_ping > Date.now()-timerInterval) {
				//last ping within two sec
				console.log('(re)connected')
				connected = true;
				notifyEvent('connectionChanged', connected)
			} else if(connected && last_ping <= Date.now()-timerInterval) {
				console.log('disconnected')
				connected = false;
				notifyEvent('connectionChanged', connected)
			}
		}, timerInterval);
	}
}



//EventSource.readyState
//0 — connecting
//1 — open
//2 — closed

//window.addEventListener('storage', ()={
	//check last timer here
//})

