import { useEffect, useState } from 'react';
import 'indian.css';

async function getNews(url)
{
	let response = await fetch();
	if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();
	} else {
	  alert("Ошибка HTTP: " + response.status);
	}
	return response
}

const NewsCont = () => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
	    const fetchData = async () => {
	      try {
	        const response = await fetch(
	          `/public/news/news.json`
	        );
	        if (!response.ok) {
	          throw new Error(`HTTP error: Status ${response.status}`);
	        }
	        let rez = await response.json();
	        //console.log(JSON.stringify(rez));
	        let fff=[];
	        for (var i in rez) {

	        	let new_one=rez[i];	 
	        	let cl_ths= 'img_wsz_'+new_one.pic_width;        	
	        	fff.push(
	<div class='NewsPart'>
    	<h1>{new_one.head}</h1>
		<img src={new_one.pic} alt="Image" class={cl_ths}/>
		<p>{new_one.msg}</p>
		<div class='nvpuff'> </div>
		<p class='italic_font data_setts'>Дата: {new_one.date}</p>
    </div>
	        		);
	        }
	        setData(fff);
	        setError(null);
	      } catch (err) {
	        setError(err.message);
	        setData(null);
	      } finally {
	        //setLoading(false);
	        //console.log('loaded');
	      }
	    };

	    fetchData();
	  }, []);	
	return (<>
<div class='News-Main-Contaner'>
{data}
</div>
	</>);
}

export default NewsCont;