import React, {useContext, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import ReactDOMclient from 'react-dom/client';

import { Link } from "react-router-dom";

import { htmlBool, defer, later} from './helpers'
import { AutosizeTextarea, getXY} from './ui_helpers'

import css from './modals.module.css'

const ModalContext = React.createContext(()=>{})
export const useModalContext = () => useContext(ModalContext)

export const PopupFrameContext = React.createContext(false);
export function usePopupFrameContext() {
	return useContext(PopupFrameContext)
}

export function PopupFrame({className, children, ...props}) {
	return <div className={className? "PopupFrame "+className: "PopupFrame"} {...props}>
		<PopupFrameContext.Provider value={true} children={children} />
	</div>
}


//NOTE: last child is modal is topmost modal 
//  (we create modal portals there!)
// so, we chould check focus there
document.addEventListener('blur', (e) => {
	//console.log(e.target, e.relatedTarget)
	const topmost_modal = 
		document.getElementById('modals')
		.lastChild
	if(e.relatedTarget &&
		topmost_modal && 
		!topmost_modal.contains(e.relatedTarget)) 
	{
		topmost_modal.focus()
		console.log('restore focus to',topmost_modal)
	}
}, true)

export const focusableSelector = "INPUT,TEXTAREA,SELECT,BUTTON";

export class Modal extends React.Component {
	constructor(props) {
		super(props)
		this.ctx = {hide:this.callHide}
	}
  modalRefChanged = (node) => {
  	//console.log('set_ref', node)
  	if(node) {
  		const focusable = this.props.toFocus
  			|| focusableSelector;
  		const to_focus = node.querySelector(focusable);
  		//console.log('to_focus', to_focus, node);
  		(to_focus||node).focus()
  	}
  }

  callHide = (result) => {
  	this.props.hide(result)
  }

  render() {
  	if(!this.props.visible) return null;

	const container = document.getElementById('modals');

	let { visible, hide, children
				, closeBox, noEsc, noClick
				, framed, className
				, style, bindX, bindY, pos,	
			 ...props } = this.props;

	let popupStyle = pos ?
	{
		...style
		, position: 'absolute'
		, [bindX]: bindX==='right'? document.body.offsetWidth - pos.x +"px"
						: pos.x+"px"
		, [bindY]: bindY==='bottom'? -pos.y +"px"
						: pos.y+"px"
	}
	:
	{
		...style
		, position:'fixed'
		, top: "50%", left:"50%"
		, transform: "translate(-50%, -50%)"
		, maxWidth: "100vw", maxHeight: "95vh"
		, overflow: "auto"
	}

	if(framed ?? true)
		className = (className ?? '') + ' PopupFrame';

	if(children instanceof Function) children = children(this.ctx)
	return ReactDOM.createPortal(<>
		<div style={{
			position:"fixed",
			left:0, top:0,
			width:"100%", height:"100%",
		}} 
		className="shadow"
		onClick={() => !noClick && this.callHide()}
		/>
		<div style={popupStyle} className={className} 
			{...props}
			close-box={htmlBool(closeBox)}
			onKeyDown={(e) => {if(!noEsc && e.keyCode === 27) this.callHide() } }
			ref={this.modalRefChanged}
			tabIndex="0"
		>
			<PopupFrameContext.Provider value={framed??true}>
				<ModalContext.Provider value={this.ctx} children={children} />
			</PopupFrameContext.Provider>
		</div>
		</>, container)
	}
}



/**
 * PopupMenu
 * props
 * trigger: element, click on them trigger popup
 * bindX: left or right popup side binded to trigger
 * bindX: top or bottom popup side binded to trigger
 * position: function to recalculate (usually offset) popup position
 * closeBox: draw gui box to close modal
 * noEsc: prevent close modal if ESC pressed
 * className: className of the popped element (PopupFrame by default)
 * other props passed to wrapper div
*/

export class PopupMenu extends React.Component {
  constructor(props) {
    super(props);
    this.triggerRef = React.createRef();
    this.state = {popped: false};
  }
  setPopped(popped) { this.setState({popped}) }
  render() {
    let {trigger, popup, children, onHide
    	, position, bindX, bindY
    	, ...props} = this.props;

    let pos = {x:0,y:0}

		if(this.triggerRef.current) {
			const tp = getXY(this.triggerRef.current);
			const {x,y,w,h} = tp;

	    pos = (position || ((x,y) => ({x,y})))
	    		( bindX === 'right'? x+w : x
	    		 ,bindY === 'bottom'? y : y+h
	    		 , tp
	    		);
		}
		return <>
			{React.cloneElement(trigger, 
					{onClick:()=> !this.props.readOnly && this.setPopped(true)
					, ref: this.triggerRef
					}
			)}
			{this.state.popped?
				<Modal visible
					bindX={bindX||'left'} bindY={bindY||'top'} 
					pos={bindX || bindY ? pos : null}
					hide={result => {
							this.setPopped(false);
							if(popup) 
								children?.(result)
							else
								onHide?.(result);
						}}
					{...props}
				>
					{popup ?? children}
				</Modal>
			: null}
	</>
  }
}


/**
 * MenuLink like Link from router but autoclose nearest modal
 * 
*/
export function MenuLink(props) {
	const {hide} = useModalContext();
	return <Link {...props} onClick={() => hide(props.value)}/>;
}

export function showModal(modal, props) {
	const previousFocus = document.activeElement; 
	let elem = document.createElement('DIV')
	let c = document.getElementById('modals')
	c.appendChild(elem);
  const root = ReactDOMclient.createRoot(elem);
	return new Promise((resolve, reject) => {
			const hide = result => {
					if(result===undefined) reject()
					else resolve(result)
				}
	    root.render(<Modal visible	
			hide={hide}
			{...props}
			children={modal}
		/>)
	})
	.finally(()=>{
				root.render(null)
				c.removeChild(elem)
		  	if(previousFocus) 
		  		defer().then(()=>previousFocus.focus())
	})
}

function ModalProcessing({operation, UI}) {
	const {hide} = useModalContext();
	const [progress, updateProgress] = useState(undefined)
	const getProgress = () => progress
	useEffect(()=>{
		updateProgress(null)
		operation(hide, updateProgress, getProgress) // can be multistage promice
		.then((ret)=>hide(ret))
		.catch(()=>hide())
	},[]); //once!
	return <UI progress={progress} updateProgress={updateProgress} />;
}

/**
 * lock UI until processing ends
 * 
 * operation: (terminate, updateProgress, getProgress) => value
 * 
 * 
 * operation take function updateProgress
 * and component take property progress
 * 
 * if operation needs interaction it can 
 * 		updateProgress to some value and component shows corresponding UI
 * 		in this case  operation should wait on newly created promise
 * 		and put 'resolve/reject' functions to progress 
 * 		component resolve/reject it later
 * 
 * if component decided to cancel operations
 * 		it can proactively updateProgress to some value 
 * 		and operation can check it with getProgress
 * 
 */
export function showModalProcessing(operation, Component, props)
{
	return showModal(<div className={css.modalProcessing}
		>
		<ModalProcessing 
			operation={operation}
			UI={Component}
		/>
	</div>
	, props)
}

export function seqModalProcessing(op, arr, UI, props) {
	if(!arr.length) return Promise.resolve()
	return showModalProcessing(async (hide, updateProgress, getProgress)=>{
			const total = arr.length
			updateProgress({done:0,total})
			while(arr.length) {
				await op(arr.shift(),updateProgress, getProgress)
				updateProgress(p=>({...p, done: total-arr.length, total}))
			}
			hide(total) // done!
		}
		, UI ??
			(
			({progress}) => 
			<div className="PopupFrame">
				{ progress? `${progress.done} / ${progress.total}`
					: '.....'
				}
			</div> //should use state in form {done, total}
			)
		, props
	)
}


export function ModalButton(props) {
	const {hide} = useModalContext();
	return <button type="button" className="modalButton"
		{...props}
		onClick={(e)=>{
			props.onClick?.(e)
			hide(props.value)
		}}
	/>
}

export function alert(text) {
	return showModal(
		<div className={css.alertBox}>
			<div>{text}</div>
			<ModalButton>OK</ModalButton>
		</div>
	, {framed:false})
	.catch(()=>{})
}

export function confirm(text, required) {
	return showModal(
		<div className={css.confirmBox}>
			<div>{text}</div>
			<div>
			<ModalButton value={true}>Да</ModalButton>
			<ModalButton value={false}>Нет</ModalButton>
			</div>
		</div>
	, {framed:false})
	.catch(()=>false)
	.then(r => {
		if(!r) throw undefined;
		return r;
	})
}

function Prompt({caption, initial, props}) {
	const {hide} = useModalContext();
	const [text,setText] = useState(initial||'')
	const arrayPhrases = props.arrayPhrases;
	return <div style={{maxWidth:"90%"}} {...props.containerProps} >
			<div>{caption}</div>
			<input value={text}
			onChange={e=>setText(e.target.value)} 
			onKeyDown={e=>{
					if(e.keyCode === 13) 
						later(0,text).then(hide) //NOTE: defer resolves too early! so, wait in timer
					}} 
		  autoComplete="off"
		  {...props.inputProps}
			/>
			{arrayPhrases.length > 1 &&
				<ul>
					{arrayPhrases.map((item, key) => (
							<li onClick={()=>setText(item)} key={key}><hr/>{item}</li>
					))}
				</ul>
			}
			<div>
			<ModalButton value={text}>Ок</ModalButton>
			<ModalButton>Отмена</ModalButton>
			</div>
		</div>
}

//FIXME: remove one div level
export function prompt(caption, initial, props) {
	if(props === undefined) props = {}
	if(typeof props === 'boolean')
		props = { required: props }
	if(props.arrayPhrases === undefined) props.arrayPhrases = []
	return showModal(
		<div className={css.promptBox}>
			<Prompt caption={caption} initial={initial} props={props} />
		</div>
	, {framed:false})
	.then(text=>{
		if(!props.asIs)
			text = text.trim()
		if(props.required && !text) throw undefined;
		return text;
	})
}

function PromptBig({caption, initial, props}) {
	const {hide} = useModalContext();
	const [text,setText] = useState(initial||'')
	return <div style={{maxWidth:"90%"}} {...props.containerProps}>
			<div style={{marginBottom:"1em"}}>{caption}</div>
			<AutosizeTextarea value={text} 
			style={{width:"30em", maxWidth: "90vw"}}
			onChange={e=>setText(e.target.value)} 
			onKeyDown={e=>{
					if(e.keyCode === 13 && e.shiftKey) 
						later(0,text).then(hide) //NOTE: defer resolves too early! so, wait in timer
					}} 
			 {...props.inputProps}
			/>
			<div style={{marginTop:"1em"}}>
			<ModalButton value={text}>OK</ModalButton>
			<ModalButton>Отмена</ModalButton>
			</div>
		</div>
}
//FIXME: remove one div level
export function promptBig(caption, initial, props) {
	if(props === undefined) props = {}
	if(typeof props === 'boolean')
		props = { required: props }
	return showModal(
		<div className={css.promptBox}>
			<PromptBig caption={caption} initial={initial} props={props} /> 
		</div>
	, {framed:false})
	.then(text=>{
		if(props.required && !text) throw undefined;
		return text;
	})
}

