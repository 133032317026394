import * as yup from "yup";

export const emailValidation = yup.string().email('Некорректный адрес').required('Обязательно');
export const passwordValidation = yup.string().min(6, 'Пароль должен быть не менее 6 символов').required('Обязательно');
export const codeValidation = yup.string().required('Обязательно').matches(/^\d{4}$/, 'Код должен содержать 4 цифры');

const dateValidation = yup
    .mixed()
    .test('isValidDate', 'Введите корректную дату', function(value) {
        return value && !isNaN(value.getTime()) && value <= new Date();
    });

export const validationSchemas = {
    1: yup.object().shape({
        email: emailValidation
    }),
    2: yup.object().shape({
        password: passwordValidation
    }),
    3: yup.object().shape({
        code: codeValidation
    }),
    4: yup.object().shape({
        email: emailValidation,
        lastName: yup.string().required('Обязательно'),
        firstName: yup.string().required('Обязательно'),
        middleName: yup.string(),
        birthDate: dateValidation.required('Обязательно').nullable(),
        password: passwordValidation,
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать').required('Обязательно')
    }),
    5: yup.object().shape({
        code: codeValidation
    })
};