import React, { useContext, createRef, useRef } from 'react'
import { useLocation } from "react-router-dom";
import { Field, ErrorMessage } from 'azlib/components/formik-fields'
import { Formik, Form } from 'formik';

import { mainDbX } from 'azlib/components/db';
import { localISOtoday } from 'azlib/components/calendar';
import { copyToBuffer } from 'azlib/components/helpers'
import { confirm, Modal } from 'azlib/components/modals';
import { Link } from 'react-router-dom';
import { saveAccountKV } from 'accounts/account'
import { encryptData, jsonEscapeUTF, generateRandText } from 'azlib/components/crypt'

import { object, string } from 'yup';
import UserInfoContext from 'UserInfo.js';

import { ReactComponent as ImgStepBack } from "app-img/stepBack.svg";


export function DocShare({doc, ...props}) {
    const uinfo = useContext(UserInfoContext)
    const location = useLocation()
    location.pathname = location.pathname.split('/').slice(0,-1).join('/')

    return (
        <Modal visible hide={false} closeBox={false}>
            <Formik initialValues={{description:''}}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={
                    object().shape({
                        description: string()
                            .required('Необходимо наименование ссылки')
                    })}
                onSubmit={async (v) => {await genDocUrl(v.description, doc, uinfo)}}>
                <Form className="form-wrapper flexContainerColumn" >
                    <Link to={location}><ImgStepBack style={{marginLeft:"-45px"}} className="back-button"/></Link>
                    <div className="form-title">НАСТРОЙКИ ДОСТУПА</div>
                    <div>
                        <p className="form-field-label">Наименование</p>
                        <div className="flexContainer">
                            <Field name="description" type="text"/>
                            <button className="pinRight" type="submit">СГЕНЕРИРОВАТЬ ССЫЛКУ</button>
                        </div>
                        <ErrorMessage name="description" component="div" className="error" />
                    </div>
                    <DocShareList url={doc.url}/>
                </Form>
            </Formik>
        </Modal>
    )
}

export function DocShareList({url, ...props}){

    const uinfo = useContext(UserInfoContext)

    let doc_sharings = uinfo.kv.docs_shared && uinfo.kv.docs_shared[url]
        ? uinfo.kv.docs_shared[url]
        : [];

    let arrLength = doc_sharings.length;
    let urlRefs = useRef([]);

    if (urlRefs.current.length !== arrLength) {
      // add or remove refs
      urlRefs.current = Array(arrLength)
        .fill()
        .map((_, i) => urlRefs.current[i] || createRef());
    }

    return (
        <div className="card">
        {
        arrLength > 0 ?
            doc_sharings.map((ds, i)=>
                <div className="flexContainerColumn">
                    <p className="form-field-label">Наименование</p>
                    <div className="flexContainer">
                        <Field
                            name="description"
                            type="text"
                            readOnly="true"
                            value = {ds.description}/>
                        <input type="hidden"
                            readOnly="true"
                            value = {'https://'+window.location.hostname+(window.location.port !== 80 ? ':'+window.location.port : '')+'/doc/'+ds.url+'#'+ds.hash}
                            ref={urlRefs.current[i]}
                        />
                        <div className="flexContainer">
                            <button type="button" onClick={async ()=>{
                                    await copyToBuffer(urlRefs.current[i].current.value)
                                }}
                                >СКОПИРОВАТЬ ССЫЛКУ</button>
                            <button type="button" onClick={async (e)=>{
                                    await confirm('Удалить?', true)
                                    .then(async () =>
                                        await dropDocUrl(url, ds.url, uinfo)
                                    )
                                }}
                                >УДАЛИТЬ ССЫЛКУ</button>
                        </div>
                    </div>
                </div>
            )
        :
        <p>У Вас пока нет ссылок доступа к данному ЦДО</p>
        }
        </div>
    )
}

async function genDocUrl(value, doc, uinfo){

    let docToShare = doc
    let docSharedURL = docToShare.url

    delete docToShare.url
    docToShare.ext_info = doc.ext_info.filter((e) => e.parameter !== 'master_key')

    if(!uinfo.kv.docs_shared){
        uinfo.kv.docs_shared = {}
    }

    let hash = generateRandText(16);
    let new_url = generateRandText(32);

    let new_share = {
        url: new_url
        , hash: hash
        , description: value
        , stamp: localISOtoday()
    }

    if(!uinfo.kv.docs_shared[docSharedURL])
        uinfo.kv.docs_shared[docSharedURL] = [new_share]
    else
        uinfo.kv.docs_shared[docSharedURL].push(new_share)

    mainDbX.add_direct('archive_shared'
        , null
        , {}
        , {
            doc_key: new_url
            , data: await encryptData(hash, jsonEscapeUTF(JSON.stringify(docToShare)))
            , stamp: localISOtoday()
        }
    )
    .then(async () => {
            await saveAccountKV(uinfo, uinfo.login, uinfo.kv)
            window.document.location.reload()
        }
    )
    .catch((error) => {
            alert("Не удалось создать ссылку! Попробуйте еще раз позднее или обратитесь в службу технической поддержки")
        }
    )
}

async function dropDocUrl(url, doc_shared, uinfo){

    await mainDbX.remove_direct('archive_shared', {doc_key: doc_shared})
    .then(async () => {
            let ds = uinfo.kv.docs_shared[url].filter((e)=> e.url !== doc_shared)

            if (ds.length === 0){
                delete uinfo.kv.docs_shared[url]
            }
            else{
                uinfo.kv.docs_shared[url] = ds
            }
            await saveAccountKV(uinfo, uinfo.login, uinfo.kv)
            window.document.location.reload()
        }
    )
    .catch((error) => {
            alert("Не удалось удалить ссылку! Попробуйте еще раз позднее или обратитесь в службу технической поддержки")
        }
    )

}