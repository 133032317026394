import { mainDbX } from 'azlib/components/db'
import { formatLocalISO } from 'azlib/components/calendar'
import {
    encryptData
    , decryptData
    , jsonEscapeUTF
    , exportPbkdf2Key
    , encryptRSA
} from 'azlib/components/crypt'
import { getUinfoTmpCookie } from 'azlib/components/cookies'
import { later } from 'azlib/components/helpers'


export async function responseText(response){
    //console.log(response)
    if (response.status !== 200)
        switch(response.status){
            case 204:
                return {error: 'Данные отсутствуют!'}
            case 400:
                return {error: 'Неверный запрос!'}
            case 403:
                return {error: 'Доступ запрещен!'}
            case 408:
                return {error: 'Время ожидания истекло!'}
            case 429:
                return {error: 'Слишком частые запросы! Повторите попытку через минуту'}
            default:
                return {error: 'Ошибка сервера!'}
        }

    let data = await response.text()
    if(data)
        data = JSON.parse(data)
    else
        return {}

    if (data.error)
        switch(data.error){
            case 'no_login':
                return {error: 'Аккаунт не найден. Повторите попытку или зарегистрируйтесь'}
            case 'bad_code':
                return {error: 'Неверный код подтверждения!'}
            case 'bad_pwd':
                return {error: 'Неверный пароль!'}
            case 'denied':
                return {error: 'Доступ запрещен!'}
            case 'mail_fail':
                return {error: 'Не удалось отправить письмо на указанную почту! Повторите попытку или обратитесь в службу технической поддержки'}
            case 'timeout':
                return {error: 'Срок действия кода истек!'}
            default:
                return {error: 'Ошибка сервера!'}
        }
    return data
}

export async function checkLoginExists(login_hash){
    let res = await responseText(await mainDbX.fetch_get("/check_login",{login: login_hash}))
    return res;
}

export async function senderMail(type, login, login_hash = null){
    let res = await responseText(await mainDbX.fetch_get("/send_mail",
        {
            login: login_hash ?? btoa(await exportPbkdf2Key(login, login))
            , mail: login
            , send: type
        }))
    return res;
}

export async function loginFOne(login, login_hash, pwd_hash, magic, salt){
    let magic_hash = btoa(await exportPbkdf2Key(await exportPbkdf2Key(atob(magic), pwd_hash), atob(login_hash)))
    let res = await responseText(await mainDbX.fetch_get("/check_pwd",{login: login_hash, magic: magic_hash}))
    if(res.login === login_hash){
        return await senderMail('code', login);
    }
    return res;
}

export async function loginFTwo(userData, code){
    let crypted = getUinfoTmpCookie()

    if(crypted === '')
        return {error: 'Срок действия кода истек!'};

    let code_salted = await exportPbkdf2Key(code, atob(crypted))

    return await responseText(await mainDbX.fetch_post("/login/"+userData.login, {login: userData.login, code_salted: btoa(code_salted)}))
        .then( async(res) => {
                if (res.error)
                    throw res
                window.localStorage['UserPwd'] = userData.pwd_hash;
                window.localStorage['UserMagic'] = userData.magic;
                window.localStorage['UserSalt'] = userData.salt;
            })
        .then(() => later(1*1000)) //wait one second to update user cache!
        .then(() => {return {success:"login"}})
        .catch((e) => {return e})
}

export async function reLogin(uinfo, new_login, code){
    let crypted = getUinfoTmpCookie()

    if(crypted === ''){
        alert('Срок действия кода истек!');
        return;
    }

    let kv = await encryptData(uinfo.pwd, jsonEscapeUTF(JSON.stringify(Object.assign(uinfo.kv, {contact_email: new_login}))))
    let magic_hash = btoa(await exportPbkdf2Key(await exportPbkdf2Key(atob(uinfo.magic), uinfo.pwd), await exportPbkdf2Key(new_login, new_login)))
    let code_salted = btoa(await exportPbkdf2Key(code, atob(crypted)))

    await responseText(await mainDbX.fetch_post("/relogin/"+uinfo.login, {login: uinfo.login, code_salted: code_salted, magic_hash: magic_hash, kv: kv}))
    .then( async(res) => {
        if (res.error)
            throw res
        alert("Логин успешно изменен!")
        window.document.location.reload()
    })
    .catch((e) => alert(e.error))
}

export async function registerFOne(values){
    return await senderMail('code', values.email);
}

export async function registerFTwo(values){
    let crypted = getUinfoTmpCookie();

    if(crypted === ''){
        return {error: 'Срок действия кода истек!'}
    }

    let code_salted = await exportPbkdf2Key(values.code, atob(crypted))
    let new_login = btoa(await exportPbkdf2Key(values.email, values.email))

    let res = await responseText(await mainDbX.fetch_get("/reg/"+new_login, {login: new_login, code_salted: btoa(code_salted)}))
    if(res.error)
        return res

    let umagic = await decryptData(code_salted, res.magic)
    let usalt = await decryptData(code_salted, res.salt)

    if(!umagic || !usalt)
        return {error: 'Неверный код подтверждения!'}

    let uinfo = JSON.stringify({
        last_name: values.lastName
        , first_name: values.firstName
        , middle_name: values.middleName ?? ""
        , bdate: values.birthDate ? formatLocalISO(values.birthDate) : ""
        , snils: values.snils ?? ""
        , contact_tel: values.phone ?? ""
        , contact_email: values.email
        , cdo_list: {}
        , docs_shared: {}
    });

    let pwd_hash = await exportPbkdf2Key(values.password, atob(usalt))
    let rsa_pwd = await encryptRSA(pwd_hash)
    let magic_hash = btoa(await exportPbkdf2Key(await exportPbkdf2Key(atob(umagic), pwd_hash), atob(new_login)))

    let new_own = {
        login: new_login
        , kv: await encryptData(pwd_hash, jsonEscapeUTF(uinfo))
        , magic_hash: magic_hash
        , rsa_pwd: rsa_pwd
    }

    return await responseText(await mainDbX.fetch_post("/reg/"+new_login, new_own))
        .then( async(res) => {
            if (res.error)
                throw res;
            window.localStorage['UserPwd'] = pwd_hash;
            window.localStorage['UserMagic'] = umagic;
            window.localStorage['UserSalt'] = usalt;
        })
        .then(() => later(1*1000)) //wait one second to update user cache!
        .then(() => alert("Вы успешно зарегистрированы!")) //wait one second to update user cache!
        .then(() => window.open("/lk","_self"))
        .catch((e) => {return e})
}