import React from 'react'
import { Field, modalFormik, ErrorMessage } from 'azlib/components/formik-fields'
import { exportPbkdf2Key, digestSHA256 } from 'azlib/components/crypt'

import { saveAccountKV } from 'accounts/account'
import { docFindExtParam } from 'docs/doc'

import { object, string } from 'yup';

export default function DocAdd({url, hash, doc, uinfo, ...props}) {
	return	<div className="flexcontainer">
				<button className="block"
				    onClick={()=>{ addToAccount(url, hash, doc, uinfo) }}
				>ДОБАВИТЬ ДОКУМЕНТ В ЛК</button>
		</div>
}

async function addToAccount(url, hash, doc, uinfo){
    let doc_mk = docFindExtParam(doc, 'master_key')

    await modalFormik({
        initialValues: { master_key: ''}
        , validationSchema:
            object({
                master_key: string()
                    .required('Необходим код подтверждения')
                    .test( 'test-mk', 'Неверный код подтверждения',
                        async function masterKeyValid(value){
                            try{
                                if(await exportPbkdf2Key(value, await digestSHA256(value)) === atob(doc_mk))
                                    return true;
                                return false;
                            }catch(error){
                                return false;
                            }
                        }
                    )
            })
        , onSubmit: async (v,ops)=>{

                let new_cdo = {
                    'url': url
                    , 'hash' : hash
                    , 'doctype_name': doc.doctype_name
                    , 'doctype_kind': doc.doctype_kind
                    , 'year_completion': doc.year_completion
                    , 'our_number': doc.our_number
                    , 'gov': doc.infoset_orginfo.name
                }

                let ed_level = doc.doctype_kind.split('-')[0] === 'mp'? 'mp' : doc.doctype_kind.split('-')[0].charAt(0);

                if(!(ed_level in uinfo.kv.cdo_list))
                    uinfo.kv.cdo_list[ed_level] = []
                uinfo.kv.cdo_list[ed_level].push(new_cdo)

                await saveAccountKV(uinfo, uinfo.login, uinfo.kv)
				window.document.location.reload()
            }
        }
        ,
        formik=> <div className="form-wrapper fixed flexContainerColumn" >
            <div className="form-title">ДЛЯ ДОБАВЛЕНИЯ ДОКУМЕНТА В ЛК ВВЕДИТЕ КОД ПОДТВЕРЖДЕНИЯ, ПЕРЕДАННЫЙ ВАМ ОБРАЗОВАТЕЛЬНОЙ ОРГАНИЗАЦИЕЙ</div>
            <p className="form-field-label">Код подтверждения</p>
            <Field name="master_key" type="text"/>
		    <div className="error"><ErrorMessage name="master_key" /></div>
            <button type="submit">ДОБАВИТЬ ДОКУМЕНТ</button>
        </div>
    , {closeBox:true})

}